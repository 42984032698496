import { h } from 'preact';
import '../scss/app.scss';

export default () => (
  <main className="container">
    <section>
      <h2>
        <a href="mailto:rpylipow@gmail.com">Ryan Pylipow</a>
      </h2>
      <div className="line" />
      <p>Father to one beautiful little girl. Husband to one lovely lady.</p>
      <p>
        Experienced full-stack engineer with a keen interest in product
        management.
      </p>
      <p>
        Currently working on <a href="https://dscout.com">dscout</a>, a remote
        research platform.
      </p>
      <p>
        Previously spent time at <a href="https://structural.com">Structural</a>{' '}
        and <a href="https://salesforce.com">Salesforce</a> by way of
        acquisition (iGoDigital first then ExactTarget).
      </p>
      <p>
        Recently moved back to the Indianapolis area after many years devouring
        deep dish pizza in Chicago.
      </p>
      <p>
        Occasionally writing code on{' '}
        <a href="https://github.com/rpylipow">Github</a> and tweeting on{' '}
        <a href="https://twitter.com/ryanpylipow">Twitter</a>.
      </p>
    </section>
  </main>
);
